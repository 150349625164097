@import '../../../styles/variables.scss';

.user-profile {
  .profile-cover {
    position: absolute;
    top: -3rem;
    width: 100%;
    height: 21rem;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(40px);
    z-index: -1;
    pointer-events: none;
    background-color: #fafafa;
  }
  .m-layout-body {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 18rem);
    background: $white;
    position: relative;
    margin-top: calc(6.875rem + 3.75rem);
    .avatar {
      position: absolute;
      top: -3.5rem;
      left: 50%;
      transform: translateX(-50%);
      margin: auto;
      width: 7.5rem;
      height: 7.5rem;
    }
    .user-info {
      padding: calc(3.75rem + 2rem) 8px 0 8px;
      hr.divider {
        margin: 1rem 0;
      }
      .info {
        display: flex;
        align-items: center;
        & > div:first-child {
          flex-shrink: 0;
          width: 9rem;
          display: inline-flex;
          align-items: center;
          gap: 6px;
          svg {
            flex-shrink: 0;
            width: 1.1rem;
            height: 1.1rem;
          }
        }
      }
    }
    .actions {
      margin-top: auto;
      padding: 0 8px 8px 8px;
      & > div {
        display: flex;
        gap: 8px;
      }
    }
  }
}
