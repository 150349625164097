@import '../../styles/variables.scss';

.checkout-form {
  padding: 0 8px 1rem 8px;
  .form-inner {
    max-width: 30rem;
  }
  .form-title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 1rem;
    font-size: 1.125rem;
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  .form-group {
    margin-bottom: 1rem;
    label {
      display: inline-block;
      font-weight: $text-medium;
      margin-bottom: 8px;
    }
    &.voucher {
      display: flex;
      button {
        margin-left: 4px;
      }
    }
  }
  .checkout-submit {
    margin-top: 2.5rem;
    font-size: 0.9rem;
  }
  .auto-renewed {
    text-align: center;
    margin-top: 1rem;
    font-size: 0.81rem;
  }
  .card-element {
    border: 1px solid #d9d9d9;
    padding: 5px 11px;
    border-radius: 9px;
    transition: all 0.3s;
    height: 40px;
    &:hover {
      border-color: #7980e0;
    }
  }
  .StripeElement--focus {
    border-color: #7980e0 !important;
    outline: 0;
    box-shadow: 0 0 0 2px #5156d333;
  }
}
