@import '../../../styles/variables.scss';

.security-login-page {
  .row {
    padding: 1rem 8px 8px 8px;
    h3 {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    & > span {
      margin-bottom: 0.75rem;
    }
  }
  .enabled-2fa {
    margin-top: 0.75rem;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .recovery-code-info {
    margin-top: 1.5rem;
    .qr-code > h3,
    .auth-apps > h3,
    .recovery-codes > h3 {
      display: block;
      margin-bottom: 1rem;
      font-size: 0.9375rem;
      font-weight: $text-medium;
      margin-bottom: 1rem;
    }
    .auth-app-list {
      margin: 1rem 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 4px;
      & > div {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        padding: 6px 8px;
        background: #ececec;
        border: 1px solid $disabled;
        border-radius: 8px;
        font-size: 0.8125rem;
      }
    }
    .recovery-codes {
      .recovery-code-actions {
        margin: 1rem 0;
        display: grid;
        grid-template-columns: 8.125rem auto;
        gap: 8px;
        button {
          font-size: 0.875rem;
        }
      }
      .recovery-code-list > div {
        height: 2.5rem;
        background: #fbfbfb;
        border: 1px solid $disabled;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.75rem;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
  .browser-sessions,
  .mobile-tokens {
    &-actions {
      margin-top: 0.75rem;
      margin-bottom: 1.5rem;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
    &-list .browser-session,
    &-list .mobile-token {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      .text-placeholder {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 4px;
      }
    }
  }
  .mobile-tokens .delete-token {
    margin-left: auto;
  }
}
