@import '../../styles/variables.scss';

.page-livestream-wrapper,
.page-playback-wrapper {
  .m-layout-body {
    min-height: calc(100vh - 55px - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-size: 1.25rem !important;
  }
  .license-available {
    padding: 6px 12px;
    margin: 8px auto 0 auto;
    width: fit-content;
    background: #d7f7df;
    border-radius: 8px;
    color: $success;
    font-weight: $text-medium;
  }
}

.page-livestream,
.page-playback {
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs-nav-add {
    display: none;
    pointer-events: none;
  }
  .ant-tabs-tab-btn > div {
    display: flex;
    align-items: center;
    gap: 4px;
    .anticon-more {
      font-size: 1.1rem;
      margin: 0;
      color: $text-color;
    }
  }
  .ant-tabs-content-holder {
    padding-top: 3px;
    background: $disabled;
  }

  .tab-extra-content-left {
    display: flex;
    button {
      padding: 0 9px;
      width: 4.1875rem;
      height: 2.5rem;
      background: $white;
      border: 1px solid $disabled;
      border-radius: 8px 8px 0px 0px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      font-size: 0.875rem;
      cursor: pointer;
      & > * {
        flex-shrink: 0;
      }
    }
    .divider {
      width: 1px;
      height: 2.5rem;
      margin: 0 8px;
      background: $disabled;
    }
  }

  .tab-option {
    padding: 1rem 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    background: $white;
    &:not(:last-child) {
      border-bottom: 1px solid $disabled;
    }
    & > div:first-child {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    & > div:last-child {
      display: inline-flex;
      align-items: center;
      gap: 8px;
    }
    .btn-selection.selected {
      border-color: $primary;
    }
  }

  .player-controls {
    height: 5.25rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1px;
    background: $disabled;
    padding-bottom: 4px;
    .control-message {
      background: $white;
      color: $text-placeholder;
      grid-column: 1 / -1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 0.8125rem;
    }
    .control-item {
      width: 100%;
      height: 100%;
      background: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.disabled > * {
        opacity: 0.35;
        pointer-events: none;
      }
      svg {
        width: 20px;
        height: 20px;
        margin-bottom: 10px;
      }
      .control-label {
        line-height: 1;
        font-size: 0.75rem;
      }
    }
  }
  .fullscreen-controls {
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    color: $white;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 8px 8px 0px 0px;
    backdrop-filter: blur(40px);
    & > div {
      width: 3.25rem;
      height: 3.25rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.2s linear;
      &.disabled {
        pointer-events: none;
        & > * {
          opacity: 0.35;
          pointer-events: none;
        }
      }
      &:hover {
        background: $primary;
      }
      &:first-child {
        border-top-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
      }
    }
    & > div svg {
      width: 1.25rem;
      height: 1.25rem;
    }
    div.divider {
      width: 1px;
      height: 1.625rem;
      margin: 0 1rem;
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .tab-layout {
    display: grid;
    grid-auto-rows: 1fr;
    gap: 4px;
    height: 16.875rem;
    background: $disabled;
  }
  .player-wrapper {
    background: $black;
    color: $text-placeholder;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    &.active {
      border: 4px solid #ffc123;
    }
  }
  .player {
    width: 100%;
    height: 100%;
    position: relative;
    color: $white;
    .ant-spin {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: 8;
    }
    .player-message {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      z-index: 9;
      background: rgba(0, 0, 0, 0.5);
      font-size: 0.625rem;
    }
    .current-datetime {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 2px 6px;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(20px);
      border-radius: 0px 8px 0px 0px;
      font-size: 0.625rem;
    }
    .device-info-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(20px);
      border-radius: 0px 0px 8px 0px;
      font-size: 0.625rem;
      padding: 3px 6px;
      svg {
        width: 0.75rem;
        height: 0.75rem;
      }
    }
    .live-icon {
      position: absolute;
      top: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(20px);
      border-radius: 0px 0px 0px 8px;
      padding: 3px 6px 1px 6px;
    }
    video {
      width: 100%;
      height: 100%;
    }
  }
}
