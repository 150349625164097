@import '../../../../styles/variables.scss';

.plan-list-page {
  .plan-list {
    padding: 1rem 8px 0 8px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 12px;
    column-gap: 9px;
    @media (min-width: 25.625rem) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .plan {
    border: 1px dashed $disabled;
    border-radius: 8px 80px 8px 8px;
    padding: 1rem 1.5rem;
    text-align: center;
    h3.label {
      font-size: 1rem;
      font-weight: $text-medium;
      margin-bottom: 8px;
    }
    div.price {
      line-height: 3rem;
      height: 3rem;
      font-size: 0.8125rem;
      color: $text-placeholder;
      margin-bottom: 2px;
      & > span {
        font-size: 2rem;
        color: $text-color !important;
      }
    }
    div.description {
      color: $text-placeholder;
      font-size: 0.8125rem;
      height: 3.75rem;
      margin-bottom: 8px;
    }
    div.cam-selection {
      font-size: 0.75rem;
      height: 5rem;
      margin-bottom: 0.75rem;
      .ant-slider {
        margin: 0.75rem 0 0 0;
        padding: 0;
      }
      .ant-slider-track {
        background: $primary;
      }
      .ant-slider-rail {
        background: $disabled;
      }
      .ant-slider-handle {
        border: 0;
        background: linear-gradient(180deg, #6065ea 0%, #3a3ea6 100%);
      }
    }
  }
  .plan.custom-plan {
    grid-column: 1 / -1;
    padding: 1.5rem;
    div.description {
      height: auto;
      margin-bottom: 0.75rem;
    }
    button {
      width: 10rem;
    }
  }

  .feature-list {
    h3.title {
      font-size: 1rem;
      margin-bottom: 1rem;
      padding-left: 8px;
    }
    .feature-plans {
      width: 100%;
      min-width: 848px;
      display: flex;
      justify-content: space-between;
      background: linear-gradient(180deg, #f0f0f0 0%, #f9f9f9 100%);
      padding: 10px 8px;
      font-weight: $text-bold;
      gap: 8px;
      & > div {
        flex-shrink: 0;
        width: 10rem;
      }
    }
    .ant-collapse {
      width: 100%;
      min-width: 848px;
      border: 0;
      & > .ant-collapse-item {
        border: 0;
      }
      .ant-collapse-header {
        padding-left: 8px;
        border-top: 1px solid $disabled;
        .feature-header {
          font-weight: $text-medium;
        }
      }
      .ant-collapse-content {
        border-top: 0;
        &-box {
          padding: 1rem 8px;
        }
        .feature {
          margin-bottom: 1rem;
          font-weight: $text-medium;
        }
        .support-list {
          display: flex;
          justify-content: space-between;
          gap: 8px;
          & > span {
            flex-shrink: 0;
            width: 10rem;
            color: $text-placeholder;
          }
        }
      }
    }
  }
}
