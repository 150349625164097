.modal-plan-updated {
  .m-modal-inner {
    text-align: center;
    padding: 2.9375rem 1rem 1.5rem 1rem;
  }
  img {
    margin-bottom: 1.875rem;
  }
  h1 {
    font-size: 1rem;
    margin-bottom: 8px;
  }
  button {
    margin-top: 1.5rem;
  }
}
