@import '../../styles/variables.scss';

.menu {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3.4375rem;
  background: $white;
  backdrop-filter: blur(27.1828px);
  border-top: 1px solid #e7e7e7;
  z-index: 3080;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  .menu-item {
    width: 100%;
    height: 100%;
    text-align: center;
    a {
      height: 100%;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    svg {
      width: 1.25rem;
      height: 1.25rem;
      margin-bottom: 6px;
    }
    span {
      line-height: 1;
      color: #8a8a8f;
      font-size: 0.6875rem;
    }
    .ant-badge sup {
      background: $danger-gradient;
      font-weight: $text-medium;
      transform: translate(70%, -6%) !important;
      font-size: 12px !important;
      padding: 0 7px !important;
      span {
        color: $white !important;
      }
    }
    &.active span {
      font-weight: $text-medium;
      color: $primary;
    }
  }
}
