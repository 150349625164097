@import '../../../../styles/variables.scss';

.checkout-wrapper {
  .checkout-details {
    padding: 1rem 8px;
    h3 {
      font-size: 1.125rem;
      margin-bottom: 1rem;
    }
    .plan-info {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      .plan-label {
        font-size: 1rem;
      }
      .popular {
        padding: 6px 8px;
      }
    }
  }
  .summary {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li:not(.divider) {
      display: flex;
      align-items: center;
      color: $text-placeholder;
      font-size: 0.8125rem;
      span:first-child {
        width: 40%;
      }
      span:last-child {
        width: 60%;
      }
    }
    li.divider {
      margin: 10px 0;
      border: 1px dashed #ededed;
    }
    span.with-tooltip {
      display: inline-flex;
      align-items: center;
      gap: 5px;
    }
  }
}
