@import '../../../styles/variables.scss';

.modal-view-schedule {
  .m-layout-body {
    padding: 1rem 8px;
    & > h3 {
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }
  }
  div.divider {
    margin: 0;
  }
  .detail-group {
    margin-bottom: 2.5rem;
    .group-title {
      font-size: 0.875rem;
      margin-bottom: 8px;
    }
  }
  .recipient-detail {
    h4 {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
      img,
      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px dashed $disabled;
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
    }
    .recipients {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      & > span {
        display: block;
        height: 2.5rem;
        line-height: 2.5rem;
        padding: 0 0.75rem;
        background: #efefef;
        border-radius: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.modal-create-update-schedule {
  form.ant-form {
    padding: 1rem 8px;
  }
  .form-title {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  .schedule-datetime-field .ant-form-item-control-input {
    min-height: 0;
  }
  div.divider {
    width: 100%;
    height: 8px;
    margin: 2rem 0;
    background: $disabled;
  }
}
