@import '../../../styles/variables.scss';

.modal-device-wizard {
  .m-layout-header .center {
    h4 {
      margin-bottom: 2px;
    }
    span {
      font-size: 0.8125rem;
      display: inline-flex;
      align-items: center;
      gap: 5px;
      svg {
        cursor: pointer;
        width: 0.875rem;
        height: 0.875rem;
      }
    }
  }
  .form-title {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
}

.modal-device-wizard,
.modal-update-device {
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  .help-info {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: 5px;
    svg {
      width: 0.9rem;
      height: 0.9rem;
      flex-shrink: 0;
    }
    span {
      font-size: 0.875rem;
    }
  }
}

.modal-manage-devices {
  .col-device-thumbnail,
  .col-device-thumbnail img {
    height: 2.8125rem;
  }
  .col-device-info {
    margin-left: 4px;
    .device-info-wrapper {
      margin-bottom: 8px;
    }
    & > div:not(:first-child) {
      background: #fafafa;
      border-radius: 4px;
      margin-top: 4px;
      padding: 6px 8px;
      display: grid;
      grid-template-columns: 5rem auto;
      gap: 1rem;
      min-height: 2rem;
    }
  }
}
