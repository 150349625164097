@import '../../styles/variables.scss';

.system-notifications {
  background: $white;
  .m-layout-body {
    padding: 1.5rem 8px;
  }
  .mark-all-as-read {
    font-size: 0.8125rem;
  }
  .notif-list h4 {
    font-size: 0.9375rem;
    margin-bottom: 1rem;
  }
  .notif-item {
    display: grid;
    grid-template-columns: 2.25rem auto 10px;
    gap: 12px;
    margin-right: 6px;
    .notif-icon {
      margin-top: 4px;
      width: 100%;
      height: 2.25rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.info {
        background: #d7fbff;
      }
      &.success {
        background: #c2e5cb;
      }
      &.warning {
        background: #ffecd6;
      }
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
    .notif-content {
      color: #606060;
      a.ant-btn.ant-btn-primary {
        margin-top: 4px;
        font-size: 13px;
      }
      &.unread {
        .message {
          color: $text-color;
        }
        .datetime {
          color: $primary;
        }
      }
      .message {
        margin-bottom: 2px;
      }
      .datetime {
        font-size: 0.75rem;
      }
      button {
        margin-top: 4px;
      }
    }
    .red-dot.unread > div {
      margin-top: 7px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $danger-gradient;
    }
  }
  .no-notif {
    width: 12.5rem;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    img {
      width: 7.5rem;
      margin-bottom: 1rem;
    }
    span {
      font-size: 0.85rem;
    }
  }
}
