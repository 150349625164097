.transaction-history {
  .transaction-id {
    display: flex;
    align-items: center;
    gap: 8px;
    text-transform: uppercase;
    margin-bottom: 0.75rem;
    svg {
      width: 1rem;
      height: 1rem;
      flex-shrink: 0;
    }
  }
  .date-paid,
  .amount-paid,
  .card-number {
    font-size: 0.8125rem;
    background: #fafafa;
    border-radius: 4px;
    padding: 8px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    & > span:first-child {
      flex-shrink: 0;
      width: 7rem;
    }
  }
}
