@import '../../styles/variables.scss';

.table-user-management,
.table-recipient-management {
  .user-info-wrapper {
    display: grid;
    grid-template-columns: 2.5rem auto;
    gap: 0.75rem;
  }
  .user-info .name {
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 0.9375rem;
    font-weight: $text-medium;
  }
  .email,
  .role,
  .two-fa,
  .status {
    font-size: 0.8125rem;
    padding: 8px;
    margin-top: 4px;
    background: #fafafa;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    & > span:first-child {
      flex-shrink: 0;
      width: 6.9rem;
    }
  }
  .role > span:last-child,
  .two-fa > span:last-child,
  .status > span:last-child {
    line-height: 1;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    .anticon {
      flex-shrink: 0;
    }
  }
}
