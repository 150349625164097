@import '../../../styles/variables.scss';

.modal-search-record {
  .m-layout-body {
    padding: 1rem 8px;
  }
  .form-item-label-with-icon .ant-form-item-label {
    svg {
      flex-shrink: 0;
      width: 1.25rem;
      height: 1.25rem;
    }
    & > label {
      font-size: 1rem;
      gap: 8px;
    }
  }
  .date-picker-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      width: 2.5rem;
    }
  }
}

.modal-share-file {
  .m-layout-body {
    padding: 1rem 8px;
  }
  .expiry-time input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .ant-input-group-addon {
    border-radius: 0 8px 8px 0;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: transparent;
    box-shadow: unset;
  }
}
