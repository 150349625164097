@import '../../../styles/variables.scss';

.recover-password-page {
  background: $white;
  .form-recovery-password {
    padding: 1.5rem 8px 1rem 8px;
    h4 {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    .ant-btn {
      height: 3rem;
      font-size: 0.875rem;
      flex-shrink: 0;
    }
  }
  .success-message {
    padding: 1rem 8px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: $white;
    img {
      width: 7.5rem;
      margin-bottom: 2.181875rem;
    }
    h3 {
      font-size: 1.125rem;
      margin-bottom: 1rem;
    }
    .ant-btn {
      flex-shrink: 0;
      margin-top: 2rem;
      height: 3rem;
    }
  }
}

.reset-password-page {
  background: $white;
  .form-reset-password {
    padding: 1.5rem 8px 1rem 8px;
    .password-requirements {
      margin-bottom: 1.5rem;
    }
    .ant-row.ant-form-item {
      display: block !important;
    }
    .ant-btn {
      flex-shrink: 0;
      height: 3rem;
    }
  }
}
