.page-not-found {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden auto;
  .row:first-child {
    width: 17.5rem;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .row:nth-child(2) {
    margin-top: 2rem;
    h1 {
      font-size: 1.5rem;
      font-weight: 400;
      margin-bottom: 4px;
    }
    span {
      font-size: 0.9rem;
    }
  }
  .row:last-child {
    margin-top: 1.5rem;
    button {
      width: 15rem;
    }
  }
}
