.modal-verify-password {
  .m-layout-body {
    padding: 1.5rem 8px;
  }
  .verify-desc {
    display: block;
    margin-bottom: 1.5rem;
  }
  a.forgot-password {
    text-align: center;
    display: block;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}
