@import '../../styles/variables.scss';

.vault-storage {
  .vault-stats {
    border-bottom: 4px solid $disabled;
  }
  .file-name {
    font-size: 0.8125rem;
    margin-bottom: 0.75rem;
  }
  .device-info,
  .file-type,
  .file-size {
    margin-top: 4px;
    background: #fafafa;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    font-size: 0.8125rem;
    & > span:first-child {
      flex-shrink: 0;
      width: 6.9rem;
    }
  }
}

.video-search-records {
  .record-date {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;
    color: $text-color;
    &.failed {
      color: $danger;
    }
    &.ready {
      color: $success;
    }
    .anticon {
      flex-shrink: 0;
    }
  }
  .device-info,
  .expired-date,
  .file-size {
    margin-top: 4px;
    background: #fafafa;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    font-size: 0.8125rem;
    & > span:first-child {
      flex-shrink: 0;
      width: 6.9rem;
    }
  }
}
