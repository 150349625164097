@import '../../styles/variables.scss';

.page-playback {
  .m-layout-header {
    h4 {
      margin-bottom: 4px;
    }
    #timerange {
      font-size: 0.8125rem;
      color: $text-placeholder;
      font-weight: 400;
    }
  }

  .playback-player {
    position: relative;
    width: 100%;
    height: 100%;

    .player-info {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      max-width: 95%;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(20px);
      border-radius: 0px 0px 8px 0px;
      padding: 2px 4px;
      color: $white;
      font-size: 0.625rem;
      svg {
        width: 0.75rem;
        height: 0.75rem;
        margin-bottom: 1px;
      }
      .device-name {
        margin-left: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .player-message,
    .ant-spin {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
      z-index: 4;
    }

    .ant-spin {
      background: rgba(0, 0, 0, 0.7);
    }

    video.video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  .playback-options {
    background: $disabled;
    padding-top: 0.875rem;
    .playback-option {
      background: #f4f4f4;
      padding: 1rem 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-child) {
        border-bottom: 1px solid $disabled;
      }
      & > div:first-child {
        display: inline-flex;
        gap: 8px;
        align-items: center;
        font-size: 0.875rem;
        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
      .change-layout {
        display: flex;
        gap: 4px;
        button.selected {
          border-color: $primary;
        }
        button:not(.selected) {
          background: transparent;
        }
      }
    }
  }
}

.fullscreen-controls {
  .divider {
    margin: 0 1.25rem !important;
  }
  &.seekbar {
    width: 21.375rem;
    padding: 7px 1rem;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    & > div {
      width: auto;
      height: auto;
      justify-content: flex-start;
    }
    .progress-bar {
      width: 100%;
      height: 4px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 2px;
      position: relative;
      cursor: pointer;
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
      .inner {
        background: #7c80de;
        width: 100%;
        height: 100%;
        border-radius: 2px;
        pointer-events: none;
      }
      .scrubber {
        position: absolute;
        top: -5px;
        left: -5px;
        width: 14px;
        height: 14px;
        background: linear-gradient(180deg, #ffffff 0%, #bdbdbd 100%), #ffffff;
        border-radius: 50%;
        pointer-events: none;
      }
    }
    .current-time {
      pointer-events: none;
      justify-content: flex-end;
      width: 3.75rem;
      flex-shrink: 0;
    }
  }
}
