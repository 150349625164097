@import '../../../styles/variables.scss';

.resend-verify-page img {
  width: 7.5rem;
  height: 7.1875rem;
  margin-bottom: 2rem;
}

.verify-message {
  background: $white;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem 8px;
  img {
    width: 7.5rem;
    height: 7.375rem;
    margin-bottom: 2.181875rem;
  }
  h4 {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }
  .ant-btn {
    flex-shrink: 0;
    margin-top: 2rem;
    height: 3rem;
  }
}
