@import '../styles/variables.scss';

.hoverable {
  cursor: pointer;
}

.flex-centered {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

hr.divider {
  margin: 1.5rem 0;
  border: 0;
  border-bottom: 1px dashed $disabled;
}

div.divider {
  width: 100%;
  height: 8px;
  margin: 2rem 0;
  background: $disabled;
}

button.base-btn {
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.hidden {
  display: none;
  visibility: hidden;
}

.no-scroll {
  position: relative;
  overflow: hidden;
}

.btn-selection {
  background: $white;
  border-color: $disabled;
  font-size: 0.85rem;
  &.selected {
    background: #e6e7ff;
    color: $primary;
    border-color: #e6e7ff;
  }
  &.read-only {
    pointer-events: none;
  }
  &:disabled {
    background: #f2f2f2;
    color: $text-color;
    border-color: $disabled;
  }
}

.preload-img {
  width: 0;
  height: 0;
  position: absolute;
  z-index: -9999999;
  pointer-events: none;
}

.popular {
  background: #ffc123;
  border-radius: 8px;
  padding: 4px 6px;
  font-size: 0.75rem;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  svg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
}

.chart-tooltip.custom-tooltip {
  background: $white;
  padding: 12px;
  color: $text-color;
  border-radius: 8px;
  span {
    display: block;
    &:first-child {
      font-size: 0.75rem;
      margin-bottom: 5px;
    }
    &:last-child {
      font-weight: 500;
    }
  }
}

.table-list-header {
  padding: 1rem 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  border-bottom: 4px solid $disabled;
  background: $white;
  &.sticky {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .left {
    flex-grow: 1;
  }
  .right {
    border-left: 1px solid $disabled;
    margin-left: auto;
    & > * {
      margin-left: 1rem;
    }
  }
}

.checkbox-large {
  &.ant-checkbox-wrapper {
    align-items: center;
    .ant-checkbox-inner,
    .ant-checkbox-input {
      width: 1.5rem;
      height: 1.5rem;
    }
    .ant-checkbox-inner::after {
      transform: rotate(45deg) scale(1.2) translate(-45%, -60%);
    }
    & > span:last-child {
      margin-top: 5px;
    }
  }
}

.form-item-label-with-icon .ant-form-item-label {
  max-height: 30px;
  svg,
  img {
    flex-shrink: 0;
    width: 1.1rem;
    height: 1.1rem;
  }
  & > label {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }
}
