@import '../../../styles/variables.scss';

.modal-playback,
.modal-livestream {
  .player-wrapper {
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    &.show {
      opacity: 1;
    }
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.modal-livestream {
  .ant-spin {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 8;
  }
  .player-message {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 9;
    span {
      text-align: center;
      font-weight: $text-regular;
      letter-spacing: 0.2px;
      color: $text-placeholder;
      font-size: 1rem;
    }
  }
  .device-info {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 8px 0;
    padding: 2px 6px;
    font-size: 0.77rem;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 3;
    .device-info-wrapper svg {
      width: 1rem;
      height: 1rem;
    }
  }
  .current-datetime {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2px 6px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    border-radius: 0px 8px 0px 0px;
    font-size: 0.75rem;
  }
  .controls-wrapper {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 4;
    .controls,
    .show-controls {
      display: flex;
      align-items: center;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 8px 8px 0px 0px;
      transition: 0.2s linear;
      backdrop-filter: blur(40px);
      &.hide {
        transform: translateY(100%);
      }
      & > div {
        width: 3.25rem;
        height: 3.25rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.2s linear;
        &:hover {
          background: $primary;
        }
        &:first-child {
          border-top-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
        }
      }
      & > div svg {
        width: 1.25rem;
        height: 1.25rem;
      }
      div.divider {
        width: 1px;
        height: 1.625rem;
        margin: 0 1rem;
        background: rgba(255, 255, 255, 0.2);
      }
    }
    .show-controls {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 5;
      transform: translateY(100%);
      pointer-events: none;
      & > div {
        width: 2rem;
        height: 2rem;
        svg {
          width: 1rem;
          height: 1rem;
        }
      }
      &.show {
        pointer-events: all;
        transform: translateY(0);
      }
    }
  }
}
