@import '../../../styles/variables.scss';

.modal-add-devices {
  .m-layout-body {
    padding: 1rem 8px;
  }
  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper input {
    background: #f0f2f5;
  }
  .device-list-group {
    margin-bottom: 1.1rem;
    .ant-collapse-header {
      padding: 5px 0;
      font-weight: $text-medium;
      margin-bottom: 8px;
    }
    .ant-collapse-arrow {
      right: 2px !important;
    }
    .ant-collapse-content-box {
      padding: 0 !important;
    }
    .device-item {
      min-height: 2rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0 1rem;
      border-radius: 4px;
      transition: all 0.2s linear;
      gap: 4px;
      i {
        color: $text-placeholder;
        transition: all 0.2s linear;
        font-weight: $text-regular;
      }
      &.active {
        color: $white;
        background: $primary-gradient;
        i {
          color: $white;
        }
      }
    }
  }
}

.modal-tab-options {
  .m-layout-body {
    padding: 1.5rem 8px 2.25rem 8px;
  }
  hr.divider {
    border-style: solid;
    margin: 1rem 0;
  }
  .option {
    display: flex;
    align-items: center;
    font-size: 1rem;
    gap: 0.75rem;
    cursor: pointer;
    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }
  svg {
    width: 1.1rem;
    height: 1.1rem;
    font-size: 1.1rem;
    flex-shrink: 0;
    color: $text-placeholder;
  }
}

.modal-edit-tab-name {
  .m-layout-body {
    padding: 1rem 8px;
  }
  .name-count {
    text-align: right;
    font-size: 0.8125rem;
    margin-top: 4px;
  }
  button {
    margin-top: 1.5rem;
  }
}

.modal-select-duration {
  .m-layout-body {
    padding: 1rem 8px;
  }
  .maximum-duration-note {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .date-group {
    display: flex;
    align-items: center;
    gap: 8px;
    label {
      width: 2.3125rem;
    }
    .date-picker {
      flex-grow: 1;
    }
    .time-picker {
      width: 7.25rem;
    }
  }
  button.select-duration {
    margin-top: 10rem;
  }
  .ant-picker-dropdown {
    .contains-record {
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      top: 1px;
      right: 3px;
      background: $success-gradient;
    }
  }  
}

.modal-export-videos {
  .m-layout-body {
    padding: 1rem 8px;
  }
  .select-time-range,
  .select-devices {
    margin-bottom: 3rem;
    .title {
      display: flex;
      align-items: center;
      font-size: 1rem;
      gap: 0.625rem;
      margin-bottom: 4px;
      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    .subtitle {
      display: block;
      font-size: 0.875rem;
      margin-left: 1.9375rem;
      margin-bottom: 1.5rem;
    }
    .date-group {
      display: flex;
      align-items: center;
      gap: 8px;
      label {
        width: 2.3125rem;
        flex-shrink: 0;
      }
      .date-picker {
        flex-grow: 1;
      }
      .time-picker {
        width: 7.25rem;
      }
    }
  }
  .select-devices {
    .ant-collapse-header {
      padding: 0;
      padding-right: 40px;
      margin-top: 1.5rem;
      font-weight: $text-medium;
      font-size: 0.875rem;
    }
    .ant-collapse-arrow {
      right: 0 !important;
    }
    .ant-collapse-content-box {
      padding: 1rem 0 0 0 !important;
      .ant-checkbox {
        margin: 0;
      }
      .device {
        height: 1.5rem;
        padding-left: 1rem;
        display: flex;
        align-items: center;
        gap: 0.75rem;
      }
      .device-info-wrapper {
        margin-top: 2px;
        .viewing {
          color: $text-placeholder;
          margin-left: 4px;
          font-style: italic;
        }
      }
    }
  }
}
