@import './variables.scss';

.text-primary {
  color: $primary !important;
}

.text-danger {
  color: $danger !important;
}

.text-warning {
  color: $warning !important;
}

.text-success {
  color: $success !important;
}

.text-info {
  color: $info !important;
}

.text-body {
  color: $text-color !important;
}

.text-placeholder {
  color: $text-placeholder !important;
}

.text-link {
  font-weight: $text-medium;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.text-underline {
  text-decoration: underline;
}

.text-bold,
.text-600 {
  font-weight: $text-bold !important;
}

.text-medium,
.text-500 {
  font-weight: $text-medium !important;
}

.text-regular,
.text-400 {
  font-weight: $text-regular !important;
}

.text-center {
  text-align: center;
}
