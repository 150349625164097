@import '../../../styles/variables.scss';

.modal-filter-activity-logs {
  .m-layout-body {
    padding: 1rem 8px;
  }
  .rc-virtual-list-holder-inner {
    gap: 6px;
  }
  .ant-select-item-option-content,
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .ant-select,
  .ant-picker {
    width: 100%;
  }
  h4 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  .date-picker-group {
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      font-size: 1rem;
    }
  }
}

.modal-filter-device-activity-logs {
  .m-layout-body {
    padding: 1rem 8px;
  }
  h3 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  .date-picker-group {
    display: flex;
    align-items: center;
    gap: 8px;
    label {
      font-size: 0.875rem;
      width: 2.5rem;
    }
    .ant-picker {
      width: 100%;
    }
  }
  button.ant-btn-primary {
    margin-top: 2.125rem;
  }
}

.modal-filter-device-notifs {
  .m-layout-body {
    padding: 1rem 8px;
  }
  div.divider {
    margin: 1.75rem 0;
  }
  h3 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  .ant-select {
    width: 100%;
  }
  .date-picker-group {
    display: flex;
    align-items: center;
    gap: 8px;
    label {
      font-size: 0.875rem;
      width: 2.5rem;
    }
    .ant-picker {
      width: 100%;
    }
  }
  .tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;
  }
  button.ant-btn-primary {
    margin-top: 2.125rem;
  }
}

.modal-filter-devices {
  .m-layout-body {
    padding: 1rem 8px;
  }
  .device-type,
  .device-location {
    h4 {
      font-size: 1rem;
      margin-bottom: 0.75rem;
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }
  button.ant-btn-primary {
    margin-top: 2.125rem;
  }
}

.modal-filter-inbound-notifs {
  .m-layout-body {
    padding: 1rem 8px;
  }
  h4 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .date-picker-group {
    display: flex;
    align-items: center;
    gap: 8px;
    label {
      font-size: 0.875rem;
      width: 2.5rem;
    }
    .ant-picker {
      width: 100%;
    }
  }
  div.divider {
    width: 100%;
    height: 8px;
    background: $disabled;
    margin: 1.75rem 0;
  }
  .tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;
  }
  button.ant-btn-primary {
    margin-top: 2.125rem;
  }
  .ant-picker-input {
    pointer-events: none;
    input {
      pointer-events: none;
    }
  }
}
