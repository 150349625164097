@import '../../styles/variables.scss';

.m-table {
  position: relative;
  overflow: hidden;

  &.loading {
    min-height: 10rem;
    .table-loading {
      position: absolute;
      width: 100%;
      min-height: 10rem;
      height: 100%;
      z-index: 20;
      background: transparentize($white, 0.5);
    }
  }
  &.empty {
    min-height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ant-empty.ant-empty-normal {
      margin: 0;
    }
  }

  .m-table-row-group:not(:last-child) .m-table-row-wrap:last-child {
    margin-bottom: 1rem;
  }

  .m-table-row-head {
    background: #f9f9f9;
    padding: 8px;
    font-weight: $text-medium;
    font-size: 0.875rem;
    &.expandable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .expand-icon {
        width: 1.25rem;
        min-width: 1.25rem;
        max-width: 1.25rem;
        display: flex;
        justify-content: center;
      }
    }
  }

  .m-table-row-wrap {
    position: relative;
    display: flex;
    overflow: hidden;
    &:not(:last-child) {
      border-bottom: 1px dashed $disabled;
    }
    &.show-action .m-table-row,
    &.show-action .m-table-row-actions {
      transform: translateX(-12.8125rem);
    }

    .m-table-row {
      width: 100%;
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      gap: 8px;
      transition: 0.2s transform;
      padding: 1rem 8px;
    }

    .m-table-col {
      flex-grow: 1;
      &.col-action {
        cursor: pointer;
        user-select: none;
        span {
          flex-grow: 1;
          width: 100%;
          height: 100%;
          color: $text-placeholder;
          font-size: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
    }

    .m-table-row-actions {
      color: $white;
      width: 12.8125rem;
      min-width: 12.8125rem;
      max-width: 12.8125rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      gap: 8px;
      padding: 0.75rem;
      background: $primary-gradient;
      transition: 0.2s transform;
    }
  }
}
