@import '../../../styles/variables.scss';

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.6875rem;
  min-height: 100vh;
  background: $white;
  .logo {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
    width: 11.0625rem;
    height: 3rem;
  }
  .form-login {
    width: 100%;
    .field-password .ant-form-item-label label {
      width: 100%;
      div {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
    .ant-btn {
      flex-shrink: 0;
      margin-top: 1rem;
      height: 3rem;
    }
  }
  .two-fa-verification {
    width: 100%;
    p {
      margin-bottom: 2rem;
    }
    .ant-form-item {
      margin-bottom: 0.75rem;
    }
    .ant-checkbox-wrapper {
      margin-bottom: 1.5rem;
    }
    .ant-btn {
      flex-shrink: 0;
      height: 3rem;
    }
  }
  .page-footer {
    margin-top: auto;
    text-align: center;
    div:first-child {
      margin-top: 15.375rem;
      margin-bottom: 2.5rem;
    }
    div:last-child {
      display: inline-flex;
      grid-gap: 2rem;
    }
  }
}
