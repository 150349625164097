@import '../../../styles/variables.scss';

.playback-controls {
  border-bottom: 1px solid $disabled;
  background: $white;
  display: flex;
  height: 8.25rem;

  .left {
    flex-shrink: 0;
    width: 3.75rem;
    border-right: 1px solid $disabled;
    .spacer:nth-of-type(1) {
      height: 1.25rem;
      background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
    }
    .spacer:nth-of-type(2) {
      height: 2rem;
      border-bottom: 2px solid $disabled;
      background: linear-gradient(180deg, #f0f0f0 0%, #f9f9f9 100%);
    }
  }
  .player-list {
    height: calc(100% - 1.25rem - 2rem);
    overflow: hidden;
    & > span {
      width: 100%;
      height: 1.25rem;
      display: block;
      background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
    }
    &-item {
      display: flex;
      align-items: center;
      height: 1.25rem;
      padding: 1px 3px;
      font-weight: 500;
      font-size: 0.75rem;
      border-bottom: 1px solid transparent;
      span {
        padding-top: 2px;
      }
      svg {
        width: 0.875rem;
        height: 0.875rem;
      }
      &:nth-child(odd) {
        background: #f4f4f4;
      }
    }
  }

  .right {
    width: 100%;
    overflow: hidden;
  }
  .playback-timeline {
    position: relative;
    overflow: hidden;
    background: linear-gradient(180deg, #f0f0f0 0%, #f9f9f9 100%);
    .current-time {
      width: 100%;
      height: 1.25rem;
      text-align: center;
      font-size: 0.6875rem;
      padding: 2px;
      pointer-events: none;
      background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
      position: sticky;
      top: 0;
      left: 0;
      & > div {
        pointer-events: none;
        transform: translateX(-1.875rem);
      }
    }
    .timeline-lines {
      display: flex;
      width: 100%;
      height: 0.75rem;
      padding: 0 8px;
      pointer-events: none;
      .segment {
        display: inline-flex;
      }
      .line {
        width: 1px;
        background: #c4c4c4;
      }
    }
    .timeline-times {
      display: flex;
      align-items: center;
      width: 100%;
      height: calc(2rem - 0.75rem);
      padding: 0 8px;
      font-size: 0.6875rem;
      user-select: none;
      pointer-events: none;
      border-bottom: 2px solid $disabled;
      & > div {
        transform: translateY(1px);
      }
    }
    .indicator {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      pointer-events: none;
      &-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 1rem);
        margin: 1rem 8px 0;
        cursor: pointer;
        z-index: 1;
      }
      .line {
        position: absolute;
        top: 0;
        left: 8px;
        width: 2px;
        height: 100%;
        background: $primary;
        pointer-events: none;
      }
      .handle {
        position: absolute;
        top: 0;
        left: 0.5px;
        z-index: 1;
      }
    }
  }

  .playback-timegraph {
    position: relative;
    overflow: auto;
    height: calc(100% - 3.25rem);
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: transparent;
    }
    .graph {
      width: 100%;
      height: 1.25rem;
      position: relative;
      background: $white;
      .indicator {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 3;
        border-bottom: 1px solid $disabled;
        &-inner {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          margin: 0 8px;
        }
        &-line {
          position: absolute;
          top: 0;
          left: 8px;
          width: 2px;
          height: 105%;
          background: $primary;
          pointer-events: none;
        }
      }
      .segment {
        position: absolute;
        width: 0.625rem;
        height: 100%;
        background: linear-gradient(180deg, #efefef 0%, #d6d6d6 100%);
        z-index: 1;
      }
      .notification {
        position: absolute;
        top: 0;
        z-index: 2;
        width: 2px;
        height: calc(100% - 1px);
        border-radius: 28px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        span {
          width: 100%;
          height: 100%;
          &:first-child {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
          }
          &:last-child {
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
          }
        }
      }
    }
  }
}

.controls-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 1.25rem 0.5rem;
  background: $white;
  border-bottom: 1px solid $disabled;
  & > .disabled {
    cursor: not-allowed;
    opacity: 0.5;
    * {
      opacity: 0.5;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
  }
  svg {
    width: 1rem;
    height: 1rem;
  }
}
