@import '../../styles/variables.scss';

.device-management {
  .device-stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .statistics-card-wrapper {
      border-radius: 0;
      background: $white;
      border-bottom: 1px solid $disabled;
      &:first-child {
        border-right: 1px dashed $disabled;
      }
    }
  }

  .live-updater {
    padding: 1rem 8px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ant-switch {
      margin-right: 8px;
    }
  }

  .table-device {
    margin-top: 4px;
    .m-table-row {
      min-height: 8.5rem;
    }
    .col-device-thumbnail,
    .col-device-thumbnail img {
      height: 2.8125rem;
    }
    .col-device-info {
      max-width: calc(100% - 7.25rem);
      .device-info-wrapper {
        margin-bottom: 8px;
      }
      & > div:not(:first-child) {
        background: #fafafa;
        border-radius: 4px;
        margin-top: 4px;
        padding: 6px 8px;
        display: grid;
        grid-template-columns: 5rem auto;
        gap: 1rem;
        min-height: 2rem;
      }
    }
  }
}

.device-location {
  .table-location {
    margin-top: 1rem;
  }
  .location-name {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 8px;
    }
  }
  .zipcode,
  .device-count {
    display: flex;
    align-items: flex-start;
    background: #fafafa;
    gap: 3rem;
    padding: 8px;
    margin-top: 4px;
    span:first-child {
      display: inline-block;
      width: 7rem;
    }
  }
}
