@import '../../../styles/variables.scss';

.modal-create-user,
.modal-create-recipient {
  .m-layout-body {
    padding: 1rem 8px;
  }
  .ant-alert.ant-alert-success {
    padding: 0.75rem;
    background: #d7f7df;
    border-radius: 8px;
    border: none;
    margin-bottom: 1rem;
    .ant-alert-message {
      font-weight: $text-medium;
      color: $success;
    }
  }
}

.modal-update-user,
.modal-update-recipient {
  .ant-form {
    padding: calc(3.75rem + 2rem) 8px 1rem 8px;
  }
}

.modal-view-user-profile,
.modal-view-recipient-profile,
.modal-update-user {
  .m-layout {
    position: relative;
    .m-layout-header,
    .m-layout-body {
      position: relative;
      z-index: 2;
    }
    .profile-cover {
      z-index: 1;
      background-color: #fafafa;
    }
  }
  .info.status > div:last-child {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }
  .view-activity-log {
    padding: 0 8px;
    a.ant-btn-primary svg {
      width: 1rem;
      height: 1rem;
      margin-right: 8px;
      transform: translateY(3px);
    }
  }
}

.modal-view-recipient-profile {
  .info > div:first-child {
    width: 10rem !important;
  }
}

.modal-update-recipient {
  .m-layout-header,
  .m-layout-body {
    position: relative;
    z-index: 2;
  }
  .profile-cover {
    z-index: 1 !important;
  }
}
