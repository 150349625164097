@import '../../styles/variables.scss';

// AccessDenied.tsx
.access-denied-wrapper {
  width: 100%;
  min-height: calc(100vh - 3.75rem - 3.4375rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  .img {
    width: 7.5rem;
    height: 7.5rem;
    img {
      width: 100%;
    }
  }
  .message {
    margin-top: 0.75rem;
    margin-bottom: 1.5rem;
    h1 {
      font-size: 1.25rem;
      margin-bottom: 4px;
    }
    span {
      font-size: 0.8125rem;
    }
  }
  a.ant-btn {
    width: 15rem;
  }
}

// Avatar.tsx
.avatar {
  overflow: hidden;
  border-radius: 50%;
  position: relative;

  &.size-small {
    width: 1.5rem;
    height: 1.5rem;
  }
  &.size-default {
    width: 2rem;
    height: 2rem;
  }
  &.size-large {
    width: 2.5rem;
    height: 2.5rem;
  }
  &.default-avatar {
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    .avatar-string {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-weight: 500;
      color: $black;
      font-size: 0.9rem;
      line-height: 1;
      user-select: none;
    }
  }
  .avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: inherit;
  }
  .avatar-picker {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 2.25rem;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(24px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover .anticon-camera {
      color: transparentize($white, 0.1);
    }
    input {
      display: none;
    }
    svg {
      width: 1.1rem;
      height: 1.1rem;
    }
  }
}

// ChannelSelection.tsx
.channel-selection-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  &.read-only .btn-selection {
    pointer-events: none;
  }
  .btn-selection:disabled {
    svg,
    img {
      opacity: 0.7;
    }
  }
  .btn-selection {
    display: flex;
    align-items: center;
    gap: 6px;
    svg,
    img {
      width: 1.25rem;
      height: 1.25rem;
    }
    & > span:last-child {
      margin: 0;
    }
  }
  .channel-message {
    width: 100%;
    margin-top: 4px;
    font-size: 0.8125rem;
  }
}

// DateTimeSelection.tsx
.datetime-selection-wrapper {
  .days {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
  .time-picker {
    margin-top: 0.75rem;
    width: 100%;
  }
  hr.divider {
    margin: 0.75rem 0;
  }
  &.read-only .times {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    .btn-selection {
      display: flex;
      align-items: center;
    }
    .anticon-arrow-right {
      color: #666666;
    }
  }
}

// DeviceInfo.tsx
.device-info-wrapper {
  display: flex;
  align-items: center;
  .device-icon {
    width: 1.25rem;
    margin-right: 6px;
    flex-shrink: 0;
  }
  .device-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a {
    font-weight: 500;
  }
  .tag {
    margin-left: 8px;
    & > span {
      font-size: 0.75rem;
      padding: 4px 6px;
    }
  }
}

// Layout.tsx
.m-layout {
  width: 100%;
  &-header {
    width: 100%;
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 8px;
    align-items: center;
    background: $white;
    &.bordered {
      border-bottom: 1px solid $disabled;
    }
    &.rounded {
      border-radius: 1rem 1rem 0px 0px;
    }
    &.sticky {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 10;
    }
    .go-back {
      font-size: 1rem;
      color: $black;
      cursor: pointer;
    }
    .left {
      text-align: left;
      justify-self: flex-start;
    }
    .center {
      text-align: center;
      justify-self: center;
      font-size: 1.0625rem;
      font-weight: $text-medium;
    }
    .right {
      text-align: right;
      justify-self: flex-end;
      .anticon {
        font-size: 1rem;
      }
    }
  }
  &-body.full-height {
    height: calc(100vh - 60px);
  }
}

// NoResult.tsx
.no-result-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .no-result-icon {
    margin-bottom: 1.5rem;
  }
  .no-result-content {
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    .title {
      font-size: 1rem;
      margin-bottom: 4px;
    }
    .sub-title {
      font-size: 0.875rem;
      color: $disabled;
      margin-bottom: 1.5rem;
    }
  }
}

// PasswordRequirements.tsx
.password-requirements {
  h4 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  ul {
    margin: 0;
    padding: 1rem;
    border-radius: 8px;
    background: #f4f4f4;
    list-style-type: none;
    li span:first-child {
      margin-right: 8px;
    }
    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

// Spinner.tsx
.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Tag.tsx
.tag {
  position: relative;
  display: inline-block;
  line-height: 1;
  border-radius: 28px;
  padding: 1px;
  z-index: 2;
  margin-right: 4px;
  &.clickable {
    cursor: pointer;
  }
  > span {
    font-size: 13px;
    padding: 6px 10px;
    align-items: center;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}

// Thumbnail.tsx
.thumbnail-wrapper {
  background: #f5f5f5;
  min-width: 5rem;
  min-height: 2.8125rem;
  &.rounded {
    border-radius: 4px;
    img {
      border-radius: 4px;
    }
  }
  img {
    width: 100%;
    object-fit: cover;
    &.loading {
      opacity: 0;
    }
  }
  .placeholder {
    width: 100%;
    min-height: 2.8125rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    & > svg {
      width: 1rem;
      height: 1rem;
      flex-shrink: 0;
    }
  }
}
