@import '../../../styles/variables.scss';

.modal-inbound-notif-detail {
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  hr.divider {
    margin: 1rem 0;
  }
  .preview-brand-logo {
    margin: 2.5rem 0 2rem 0;
    text-align: center;
    img {
      max-width: 10rem;
    }
  }
  .title-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    & > div:first-child {
      display: inline-flex;
      align-items: center;
      font-size: 1rem;
      gap: 8px;
      svg {
        flex-shrink: 0;
        width: 1.1rem;
        height: 1.1rem;
      }
    }
    & > div:last-child {
      display: inline-flex;
      align-items: center;
      gap: 8px;
    }
  }
  .detail-group {
    &-title {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;
      svg,
      img {
        flex-shrink: 0;
        width: 1.1rem;
        height: 1.1rem;
      }
    }
    &-content {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      .tag {
        margin: 0;
      }
    }
    .attachment {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      padding: 8px;
      background: #efefef;
      border-radius: 8px;
      color: $text-color;
    }
  }
  .notif-note {
    display: flex;
    gap: 8px;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    .avatar {
      flex-shrink: 0;
    }
    .note-content {
      & > div:first-child {
        background: #f0f2f5;
        border-radius: 1rem;
        padding: 0.75rem;
        margin-bottom: 2px;
        .username {
          font-size: 0.8125rem;
          font-weight: $text-medium;
          margin-bottom: 4px;
        }
        .body {
          font-size: 0.9375rem;
        }
      }
      & > div:last-child {
        display: inline-flex;
        font-size: 0.75rem;
        align-items: center;
        gap: 5px;
      }
    }
  }
  .preview-notif-note {
    background: #f0f2f5;
    border-radius: 8px;
    padding: 8px 8px 8px 14px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      background: $primary-gradient;
      border-radius: 8px 0 0 8px;
    }
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    & > div:first-child {
      margin-bottom: 5px;
    }
  }

  .table-outbound-notification {
    .sent-to {
      margin-bottom: 1rem;
    }
    .channel,
    .status,
    .timestamp {
      margin-top: 4px;
      padding: 8px;
      background: #fafafa;
      border-radius: 4px;
      display: flex;
      align-items: center;
      gap: 2rem;
      font-size: 0.8125rem;
      & > span:first-child {
        flex-shrink: 0;
        width: 6rem;
      }
    }
    .status .anticon {
      margin-right: 4px;
    }
  }
}

.modal-add-note {
  .m-layout-body {
    padding: 1rem 8px;
  }
  textarea {
    margin-bottom: 1rem;
  }
}
