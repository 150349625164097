@import '../../styles/variables.scss';

.shared-vault-file {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $black;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
