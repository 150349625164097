body {
  font-family: 'Poppins', sans-serif;
}

[ant-click-animating-without-extra-node]:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

.ant-btn {
  border-radius: 8px !important;
  box-shadow: unset !important;

  & > svg + span {
    margin-left: 8px;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.ant-btn-primary {
    background: $primary-gradient;
    color: $white;
  }

  &.ant-btn-secondary,
  &.ant-btn-ternary {
    color: $text-color;
    border: 1px solid $disabled;
    svg * {
      fill: $text-color;
    }
  }

  &.ant-btn-secondary {
    background: $secondary-gradient;
    &.lighter {
      background: $secondary-gradient-lighter;
    }
    &:focus {
      background: linear-gradient(180deg, #ededed 0%, #d5d5d5 100%);
    }
  }

  &.ant-btn-ternary {
    background: $white;
    &.lighter {
      color: $white;
      background: rgba(255, 255, 255, 0.2);
    }
    &:focus {
      background: $text-disabled;
    }
  }
}

.ant-input,
.ant-picker,
.ant-input-affix-wrapper,
.ant-dropdown-menu,
.ant-notification-notice,
.ant-select-selector {
  border-radius: 8px !important;
}

.ant-input-affix-wrapper .ant-input,
.ant-input-password input.ant-input {
  border-radius: 0 !important;
}

.ant-checkbox-wrapper .ant-checkbox-inner {
  border-radius: 4px;
}

.ant-picker-ok button {
  color: $white !important;
}

.ant-form {
  .ant-row.ant-form-item {
    .ant-form-item-label label {
      white-space: pre-line;
    }
    .ant-col.ant-form-item-label label::before {
      position: absolute;
      right: -10px;
      margin-right: 0;
    }
  }
  .ant-form-item-label label {
    font-weight: 500;
  }
}

.ant-form-item-extra {
  display: flex;
  white-space: pre-wrap;
  align-items: center;
  font-size: 0.8125rem;
  margin-top: 2px;
  color: $text-placeholder;
  & > span {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }
  span.anticon,
  svg {
    flex-shrink: 0;
    width: 0.75rem;
    height: 0.75rem;
  }
}

.ant-notification {
  z-index: 3090;
  .ant-notification-notice-content {
    display: grid;
    grid-template-columns: auto 1rem;
  }
  .ant-notification-notice-message {
    font-size: 0.875rem;
    font-weight: 500;
    &:empty {
      margin-bottom: 0;
    }
  }
  .ant-notification-notice-description {
    font-size: 0.85rem;
  }
}

.ant-popover {
  padding: 0;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    border-radius: 8px;
    .ant-popover-title {
      padding: 0;
      border-bottom: none;
    }
  }
}

.ant-tabs {
  &.ant-tabs-card .ant-tabs-nav-list {
    .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 8px;
    }
    .ant-tabs-tab {
      border-radius: 8px 8px 0 0 !important;
      background-color: transparent !important;
      border-color: #d8d8d8 !important;
      transition: unset !important;
    }
    .ant-tabs-tab-active {
      background-color: #e7e8ff !important;
      border-top-color: #a6a9ff !important;
      border-left-color: #a6a9ff !important;
      border-right-color: #a6a9ff !important;
      border-bottom-color: #d8d8d8 !important;
      .ant-tabs-tab-btn {
        transition: unset !important;
      }
    }
  }
  .ant-tabs-nav {
    padding: 0 8px;
    background: $white;
    &::before {
      border-color: $disabled;
    }
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
}

.ant-tooltip {
  padding: 0;
  max-width: 23.75rem;
  .ant-tooltip-arrow {
    display: none;
  }
  .ant-tooltip-inner {
    color: $white;
    font-size: 0.875rem;
    border-radius: 8px;
    background-color: $black;
    padding: 0.75rem;
  }
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    border-radius: 12px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    .ant-picker-cell-inner {
      border-radius: 8px !important;
    }
    .ant-picker-content tbody {
      .ant-picker-cell-today {
        &::hover .ant-picker-cell-inner {
          background: $danger-gradient !important;
        }
        .ant-picker-cell-inner {
          background: $danger-gradient !important;
          color: $white;
          border-radius: 8px;
          &::before {
            border-color: transparent;
          }
        }
      }
    }
  }
  .ant-picker-preset .ant-tag-blue {
    border-radius: 6px;
  }
}
