@import '../../styles/variables.scss';

.verify-recipient-email {
  width: 100%;
  min-height: 100vh;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  .icon {
    margin-bottom: 1rem;
    span {
      font-size: 3rem;
    }
    .success {
      color: $success;
    }
    .failed {
      color: $danger;
    }
  }
  .title {
    font-size: 1.3rem;
    margin-bottom: 4px;
  }
  button {
    margin-top: 1.5rem;
  }
}
