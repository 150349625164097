@import '../../styles/variables.scss';

.all-device-notifications {
  .device-stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-bottom: 8px solid #f4f4f4;
    .statistics-card-wrapper {
      border-radius: 0;
      &:nth-child(odd) {
        border-right: 1px dashed $disabled;
      }
      &:nth-last-child(n + 3) {
        border-bottom: 1px dashed $disabled;
      }
    }
  }
  .table-list-header {
    border-bottom-width: 1px;
  }
  .table-notification {
    .col-detail {
      display: grid;
      grid-template-columns: 2.5rem auto;
      gap: 1rem;
    }
    .notif-tag {
      width: 100%;
      height: 2.5rem;
      display: flex;
      flex-direction: column;
      &.no-tag {
        border-radius: 8px;
        border: 1px solid $disabled;
      }
      div {
        width: 100%;
        height: 100%;
        padding: 1px;
      }
    }
    .notif-detail {
      .device-info,
      .recipient-count,
      .timestamp {
        margin-top: 4px;
        padding: 8px;
        background: #fafafa;
        display: flex;
        border-radius: 4px;
        gap: 1.5rem;
        font-size: 0.8125rem;
        & > span:first-child {
          width: 6.9rem;
        }
      }
    }
  }
}

.notification-tags {
  .tag-info {
    margin-bottom: 0.75rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    .tag {
      margin-right: 0;
    }
    .active-status {
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      gap: 5px;
      font-size: 0.8125rem;
    }
  }
  .title-match,
  .content-match,
  .status {
    margin-top: 4px;
    padding: 8px;
    font-size: 0.8125rem;
    background: #fafafa;
    border-radius: 4px;
    display: flex;
    gap: 1.5rem;
    & > span:first-child {
      width: 9.5rem;
    }
  }
  .status > span:last-child {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    line-height: 1;
  }
}
