@import '../../../styles/variables.scss';

.general-page {
  .row {
    padding: 1rem 8px;
    h3 {
      margin-bottom: 1rem;
      font-size: 1rem;
    }
    span {
      display: block;
      margin-bottom: 1rem;
      color: $text-placeholder;
    }
  }
  .change-smtp {
    color: $primary;
    font-size: 0.85rem;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .divider {
    margin: 1rem 0;
  }
}
