@import '../../../styles/variables.scss';

.register-page {
  background: $white;
  .form-register {
    padding: 1rem 8px;
    .ant-row.ant-form-item {
      display: block;
    }
  }
  .password-requirements {
    margin-bottom: 2rem;
  }
  .ant-btn {
    flex-shrink: 0;
    height: 3rem;
  }
}
