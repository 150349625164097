@import '../../styles/variables.scss';

.dashboard-page {
  .m-layout-body {
    min-height: calc(100vh - 60px - 55px);
    background: #fafafa;
  }
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs-content-holder {
    background: transparent;
    padding: 1.5rem 8px;
  }

  .device-notification {
    h3 {
      font-size: 1rem;
      margin-bottom: 0.75rem;
    }
    .ant-select {
      width: 100%;
      margin-bottom: 0.75rem;
    }
    .notif-summary {
      background: $primary-gradient;
      color: $white;
      padding: 1.5rem 8px;
      border-radius: 8px;
    }
    .ant-radio-button-wrapper {
      color: $white;
      background: transparent;
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      span svg {
        width: 0.75rem;
        height: 0.75rem;
        margin-right: 8px;
        path {
          fill: $white;
        }
      }
      &.ant-radio-button-wrapper-checked {
        background: $white;
        color: $primary;
        border-color: $white;
        span svg path {
          fill: $primary;
        }
      }
    }
    .summary-title {
      padding: 8px;
      display: flex;
      align-items: center;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      margin-top: 0.75rem;
      margin-bottom: 1.5rem;
      span {
        font-size: 0.8125rem;
      }
      svg {
        width: 1rem;
        height: 1rem;
        margin-right: 8px;
      }
    }
    .total-today-notif {
      margin-bottom: 1rem;
      h4 {
        color: $white;
        font-size: 0.9375rem;
        font-weight: $text-regular;
      }
      span {
        font-size: 2rem;
        font-weight: $text-medium;
      }
    }
    .notif-chart {
      .ant-spin span {
        color: $white;
      }
    }
  }

  .notif-tag-summary {
    h3 {
      font-size: 1rem;
      margin-bottom: 8px;
    }
    span.hoverable {
      font-size: 0.9375rem;
    }
    .summary-actions {
      margin-top: 0.75rem;
      display: grid;
      grid-gap: 8px;
      grid-template-columns: 40% auto;
    }
    .tag-summary {
      margin-top: 0.75rem;
      background: $white;
      padding: 1rem;
      border-radius: 8px;
      &-item {
        padding: 0.75rem;
        background: #fafafa;
        display: grid;
        grid-template-columns: max-content auto;
        grid-gap: 0.75rem;
        align-items: center;
        border-radius: 8px;
        &:not(:last-child) {
          margin-bottom: 0.75rem;
        }
        .notif-count {
          font-size: 1rem;
        }
        .tag {
          height: 2.0625rem;
        }
      }
    }
  }

  .resource-summary {
    h3 {
      font-size: 1rem;
      margin-bottom: 0.75rem;
    }
    a.ant-btn-lg {
      margin-bottom: 0.75rem;
    }
    .warning-card-wrapper {
      margin-bottom: 8px;
    }
    .statistics-card-wrapper {
      background: $white;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .device-statuses .device-status {
      display: flex;
      align-items: center;
      svg,
      span.text-placeholder {
        margin-right: 8px;
      }
      span:last-child {
        margin-left: auto;
      }
    }
  }

  .lastest-device-notifications {
    h3 {
      font-size: 1rem;
      margin-bottom: 1.25rem;
    }
    .no-notif {
      display: flex;
      align-items: center;
      svg {
        width: 2.75rem;
        margin-right: 8px;
      }
    }
    .notif-list .notif-list-item {
      display: grid;
      grid-template-columns: 2.25rem auto 1.5rem;
      grid-gap: 1rem;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 0.75rem;
      margin-bottom: 0.75rem;
    }
    .notif-tag {
      margin-top: 4px;
      height: 2rem;
      display: flex;
      flex-direction: column;
      div {
        width: 100%;
        height: 100%;
        padding: 1px;
      }
    }
    .notif-content {
      div:nth-child(2) {
        margin-top: 2px;
        margin-bottom: 8px;
      }
      div:nth-child(3) {
        font-size: 0.8125rem;
      }
    }
  }
}

.modal-notif-more-options {
  .m-layout-body {
    padding: 1rem 8px;
    .option {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      &:first-child {
        margin-bottom: 1.1rem;
      }
      svg {
        width: 1rem;
        height: 1rem;
      }
      span:last-child {
        font-size: 1rem;
      }
    }
  }
}
