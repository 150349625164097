@import '../../../../styles/variables.scss';

.payment-settings-page {
  .statistics-card-wrapper {
    border-bottom: 1px dashed $disabled;
  }
  .plan-features .plan-includes {
    h3 {
      height: 3rem;
      margin: 0;
      display: flex;
      align-items: center;
      padding: 0 8px;
      font-size: 0.8125rem;
      font-weight: $text-bold;
      background: linear-gradient(180deg, #f0f0f0 0%, #f9f9f9 100%);
    }
    & > div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 4px;
      padding: 8px;
      &:not(:last-child) {
        border-bottom: 1px dashed $disabled;
      }
      span {
        width: 100%;
        display: block;
      }
    }
  }
  .plan-features .ant-collapse {
    border: 0;
    & > .ant-collapse-item {
      border: 0;
    }
    .ant-collapse-header {
      border-top: 1px solid $disabled;
      .feature-header {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: $text-medium;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .ant-collapse-content {
      border-top: 0;
      &-box {
        padding: 0;
      }
      .feature {
        padding: 8px;
        min-height: 3.125rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        &:not(:last-child) {
          border-bottom: 1px solid $disabled;
        }
        & > span:first-child {
          width: 10.3125rem;
        }
      }
    }
  }
}
