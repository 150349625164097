@import '../../styles/variables.scss';

.m-pagination {
  width: 100%;
  display: flex;
  .ant-radio-button-wrapper {
    background: $secondary-gradient;
    &:first-child {
      border-radius: 8px 0px 0px 8px;
    }
    &:nth-child(2) {
      flex-grow: 1;
      text-align: center;
      font-size: 0.875rem;
    }
    &:last-child {
      border-radius: 0px 8px 8px 0px;
    }
    &:focus-within {
      box-shadow: unset !important;
    }
    &:not(.ant-radio-button-wrapper-disabled):hover {
      color: $text-color;
    }
  }
}

.modal-switch-page {
  .m-layout-body {
    padding: 1rem 8px;
  }
  .ant-input-affix-wrapper,
  .ant-input {
    background: #f0f2f5;
  }
  .page-list {
    list-style-type: none;
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 0;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1rem;
      color: $text-color;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &.active {
        color: $primary;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
