@import '../../../styles/variables.scss';

.modal-edit-profile {
  .m-layout-header {
    position: relative;
    z-index: 2;
  }
  .profile-cover {
    position: absolute;
    top: -3rem;
    width: 100%;
    height: 21rem;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(40px);
    z-index: 1;
    pointer-events: none;
    background-color: #fafafa;
  }
  .m-layout-body {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    min-height: 30vh;
    background: $white;
    position: relative;
    margin-top: calc(6.875rem + 3.75rem);
    .avatar {
      position: absolute;
      top: -3.5rem;
      left: 50%;
      transform: translateX(-50%);
      margin: auto;
      width: 7.5rem;
      height: 7.5rem;
    }
  }
  form.ant-form-vertical {
    padding: calc(3.75rem + 2rem) 8px 1rem 8px;
  }
}

.modal-change-password {
  .m-layout-body {
    padding: 1rem 8px;
  }
  .password-requirements {
    margin-bottom: 2rem;
  }
}
