$primary: #5156d3;
$white: #ffffff;
$black: #000000;
$disabled: #dedede;
$info: #1dadcd;
$success: #0b9830;
$warning: #ec9b0e;
$danger: #cc2020;

$primary-gradient: linear-gradient(180deg, #6065ea 0%, #3a3ea6 100%);
$secondary-gradient: linear-gradient(180deg, #f5f5f5 0%, #eaeaea 100%);
$secondary-gradient-lighter: linear-gradient(180deg, #ffffff 0%, #f1f1f1 100%);
$danger-gradient: linear-gradient(180deg, #f96e6e 0%, #cc2020 100%);
$success-gradient: linear-gradient(180deg, #46d86c 0%, #0b9830 100%);

$text-color: #303030;
$text-placeholder: #808080;
$text-disabled: #f2f2f2;

$text-regular: 400;
$text-medium: 500;
$text-bold: 600;
