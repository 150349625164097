.activity-logs {
  .log-description {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9375rem;
    color: #606060;
    svg {
      flex-shrink: 0;
      width: 1rem;
      height: 1rem;
    }
  }
  .happened-at,
  .initiated-by,
  .ip-address {
    background: #fafafa;
    border-radius: 4px;
    padding: 8px;
    margin-top: 8px;
    font-size: 0.8125rem;
  }
}
