@import '../../styles/variables.scss';

.plan-info-card-wrapper {
  background: #f8f5ff;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  &.rounded {
    border-radius: 10px;
  }
  svg {
    width: 1.12rem;
    height: 1.12rem;
    flex-shrink: 0;
    margin-top: 6px;
    margin-right: 0.75rem;
  }
  .plan-message {
    display: block;
    font-size: 0.875rem;
    margin-bottom: 2px;
  }
}

.statistics-card-wrapper {
  padding: 1rem;
  background: $white;
  border-radius: 10px;
  .card-inner {
    display: flex;
    .icon-wrapper {
      flex-shrink: 0;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 1rem;
      align-self: center;
      justify-self: flex-start;
      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .content {
      flex-grow: 1;
      margin-top: 3px;
      h1 {
        line-height: 1;
        font-size: 0.875rem;
        font-weight: 400;
        & * {
          font-size: 1rem !important;
        }
      }
      span {
        font-size: 1rem;
      }
    }
    .action {
      margin-left: 12px;
      color: $primary;
      font-weight: 500;
    }
  }
}

.warning-card-wrapper {
  background: #ffeccc;
  border-radius: 10px;
  padding: 1rem;
  .card-content {
    display: grid;
    grid-template-columns: 1.5rem auto;
    gap: 1rem;
  }
  .card-icon {
    align-self: center;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .card-message {
    font-size: 0.85rem;
  }
  .card-action {
    margin-left: 2.5rem;
    margin-top: 8px;
  }
}

.credit-card-wrapper {
  width: 7rem;
  background: #f7f7f7;
  border: 2px solid #f7f7f7;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s linear;
  &:hover {
    background: #f5f6ff;
    .card-spacer {
      background: #a8abe9;
    }
  }
  &.active {
    border-color: #6065ea;
  }
  .card-header,
  .card-content {
    padding: 8px;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
  }
  .card-header {
    height: 1.75rem;
    img {
      width: 2rem;
      height: 2rem;
    }
  }
  .card-content {
    justify-content: center;
    text-align: center;
  }
  .card-spacer {
    height: 12px;
    background: #efefef;
    transition: 0.2s linear;
  }
}
