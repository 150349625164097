@import '../../styles/variables.scss';

.page-device-detail {
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs-content-holder {
    border-top: 4px solid #f4f4f4;
  }
  .m-layout-header {
    .sub-header {
      font-size: 0.8rem;
      margin-top: 2px;
      font-weight: $text-regular;
      color: $text-placeholder;
    }
    .right .anticon-more {
      font-size: 1.2rem;
      cursor: pointer;
    }
  }

  // DEVICE DETAILS
  .tab-device-details {
    .cloud-stats {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      border-bottom: 8px solid #f4f4f4;
      .statistics-card-wrapper:first-child {
        border-right: 1px dashed $disabled;
        border-radius: 0;
      }
    }
    .smtp-stats,
    .camera-stats,
    .device-stats {
      background: $white;
      padding: 1rem 8px;
      border-bottom: 8px solid #f4f4f4;
      .stat-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        h3 {
          font-size: 1rem;
          margin: 0;
        }
      }
      .stat-item {
        display: flex;
        gap: 1rem;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
        span:first-child {
          flex-shrink: 0;
          width: 6.25rem;
          color: $text-placeholder;
        }
        span:last-child {
          flex-grow: 1;
          font-weight: $text-medium;
          word-break: break-word;
          p {
            display: inline-block;
            margin-bottom: 0;
            margin-left: 6px;
          }
        }
      }
    }
    .smtp-stats {
      background: #7c80de;
      color: $white;
      h3 {
        color: $white;
      }
      .stat-item span:first-child {
        color: #f4f4f4;
      }
      .stat-message {
        background: $primary;
        padding: 8px 0.75rem;
        border-radius: 8px;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        svg {
          flex-shrink: 0;
          margin-right: 10px;
        }
      }
    }
    .device-stats .stat-item span:first-child {
      width: 8.125rem;
    }
  }

  // DEVICE SCHEDULES
  .tab-device-schedules {
    .table-list-header {
      border-bottom-width: 1px;
    }
    .schedule-name {
      margin-bottom: 1rem;
    }
    .schedule-time,
    .recipient-count,
    .status {
      background: #fafafa;
      margin-bottom: 4px;
      padding: 8px;
      display: flex;
      gap: 1rem;
      span {
        font-size: 0.8125rem;
      }
      span:first-child {
        width: 6.875rem;
      }
    }
  }

  // INBOUND NOTIFICATIONS
  .tab-inbound-notifications {
    .device-stats {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      border-bottom: 8px solid #f4f4f4;
      .statistics-card-wrapper {
        border-radius: 0;
        &:nth-child(odd) {
          border-right: 1px dashed $disabled;
        }
        &:nth-last-child(n + 3) {
          border-bottom: 1px dashed $disabled;
        }
      }
    }
    .table-list-header {
      border-bottom-width: 1px;
    }
    .table-inbound-notification {
      .col-detail {
        display: grid;
        grid-template-columns: 2.5rem auto;
        gap: 1rem;
      }
      .notif-tag {
        width: 100%;
        height: 2.5rem;
        display: flex;
        flex-direction: column;
        &.no-tag {
          border-radius: 8px;
          border: 1px solid $disabled;
        }
        div {
          width: 100%;
          height: 100%;
          padding: 1px;
        }
      }
      .notif-detail {
        .recipient-count,
        .timestamp {
          margin-top: 4px;
          padding: 8px;
          background: #fafafa;
          display: flex;
          border-radius: 4px;
          gap: 1.5rem;
          font-size: 0.8125rem;
          & > span:first-child {
            width: 6.9rem;
          }
        }
      }
    }
  }

  // VIDEO VAULT
  .table-video-vault {
    .file-name {
      font-size: 0.8125rem;
      margin-bottom: 0.75rem;
    }
    .file-type,
    .file-size,
    .created-at {
      margin-top: 4px;
      background: #fafafa;
      border-radius: 4px;
      padding: 8px;
      display: flex;
      align-items: flex-start;
      gap: 1.5rem;
      font-size: 0.8125rem;
      & > span:first-child {
        flex-shrink: 0;
        width: 6.9rem;
      }
    }
  }

  // ACTIVITY LOGS
  .table-device-activity-logs {
    .log-desc {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 0.9375rem;
      margin-bottom: 8px;
      color: #606060;
      svg {
        width: 1rem;
        height: 1rem;
        flex-shrink: 0;
      }
    }
    .ip-address,
    .happened-at {
      background: #fafafa;
      border-radius: 4px;
      margin-top: 4px;
      padding: 8px;
      margin-left: calc(1rem + 8px);
      font-size: 0.8125rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      & > span:first-child {
        flex-shrink: 0;
        width: 6rem;
      }
    }
  }
}

.modal-device-more-options {
  .m-layout-body {
    padding: 1rem 8px;
  }
  hr.divider {
    margin: 1rem 0;
  }
  .option {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    &:first-child {
      margin-bottom: 1.1rem;
    }
    svg {
      width: 1rem;
      height: 1rem;
    }
    span:last-child {
      font-size: 1rem;
    }
  }
}
