@import '../../styles/variables.scss';

.m-modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 3090;
  background: transparentize($black, 0.3);
  .m-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    max-height: 100vh;
    display: flex;
    background: transparent;
    overflow: hidden auto;
    &-inner {
      border-radius: 1rem 1rem 0px 0px;
      width: 100%;
      margin-top: auto;
      background: $white;
      pointer-events: all;
      cursor: auto;
      padding: 1rem 8px;
      &.no-padding {
        padding: 0 !important;
      }
    }
  }
}

.modal-confirm {
  text-align: center;
  .icon {
    margin: 1rem 0;
    text-align: center;
    .anticon {
      font-size: 3rem;
    }
    img {
      width: 3rem;
      height: 3rem;
    }
  }
  .title {
    font-size: 1rem;
  }
  .actions {
    margin-top: 1.5rem;
    button:last-child {
      margin-top: 1rem;
    }
  }
}

.modal-info {
  .info-img {
    margin: 1rem auto;
    width: 5rem;
    height: 5rem;
  }
  .info-title {
    text-align: center;
    font-size: 1.125rem;
    margin: 0;
  }
  .info-content {
    margin-top: 1.5rem;
    font-size: 0.875rem;
  }
}

.modal-player {
  .m-modal-inner {
    border-radius: 0;
    background: $black;
    color: $white;
    position: relative;
    height: 100vh;
    max-height: 100vh;
  }
  .close-icon {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.3rem;
    height: 2.3rem;
    cursor: pointer;
    transition: 0.2s linear;
    backdrop-filter: blur(20px);
    border-radius: 0 0 0 8px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    &:hover {
      background: $primary;
    }
  }
}

.modal-search {
  .m-layout-body {
    padding: 1rem 8px;
  }
  .ant-input-affix-wrapper,
  .ant-input {
    background: #f0f2f5;
  }
  .recent-search {
    margin-top: 1rem;
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        font-size: 1rem;
        margin-bottom: 1rem;
      }
    }
    &-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }
}
