@import '../../../styles/variables.scss';

.modal-view-tag-detail {
  .ant-tabs-nav {
    margin: 0;
  }
  .detail-title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 1.5rem;
    svg {
      width: 1rem;
      height: 1rem;
      flex-shrink: 0;
    }
    h3 {
      font-size: 1rem;
      margin: 0;
    }
  }
  .detail-group {
    &:not(:last-child) {
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
      border-bottom: 1px dashed $disabled;
    }
    & > div:first-child {
      font-weight: $text-medium;
      margin-bottom: 8px;
    }
  }
  .table-affected-devices {
    .device-info-wrapper {
      margin-bottom: 8px;
    }
    .device-type {
      padding: 8px;
      border-radius: 4px;
      background: #fafafa;
      font-size: 0.8125rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      & > span:first-child {
        width: 6rem;
      }
    }
  }
  .recipient-detail {
    h4 {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
      img,
      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px dashed $disabled;
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
    }
    .recipients {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      & > span {
        display: block;
        height: 2.5rem;
        line-height: 2.5rem;
        padding: 0 0.75rem;
        background: #efefef;
        border-radius: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.modal-create-notif-tag,
.modal-update-notif-tag {
  .m-layout-header .center {
    h4 {
      margin-bottom: 2px;
    }
    span {
      font-size: 0.8125rem;
    }
  }
  div.divider {
    width: 100%;
    height: 8px;
    background: $disabled;
    margin: 2rem 0;
  }
  .form-group-title {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    svg {
      width: 1rem;
      height: 1rem;
      flex-shrink: 0;
    }
    h3 {
      font-size: 1rem;
      margin: 0;
    }
  }
  .tag-colors {
    margin-top: 2px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    .tag {
      margin: 0;
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  .ant-radio-group {
    label:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    label:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.modal-update-notif-tag {
  .ant-tabs-nav {
    margin: 0;
  }
}
