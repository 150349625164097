@import '../../styles/variables.scss';

.app-menu {
  background: $white;
  padding: 8px 8px 1.5rem 8px;
  h1 {
    margin-bottom: 1rem;
  }
  .app-menu-group {
    margin-bottom: 2rem;
    .group-title {
      font-size: 0.9375rem;
      margin-bottom: 8px;
    }
  }
  .app-menu-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.75rem;
    background: #f5f7f8;
    border-radius: 8px;
    gap: 0.875rem;
    font-size: 0.875rem;
    .menu-icon {
      width: 1.25rem;
      height: 1.25rem;
      font-size: 1.25rem;
    }
    .anticon-right {
      margin-left: auto;
    }
    &.current-user {
      gap: 8px;
      .user-name {
        font-size: 1rem;
      }
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .logout span {
    font-size: 0.9375rem;
  }
}
