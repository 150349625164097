@import '../../../styles/variables.scss';

.modal-location-detail {
  .m-layout-body {
    padding: 1rem 8px;
    h4 {
      font-size: 1rem;
      margin-bottom: 8px;
    }
    span {
      font-size: 0.875rem;
      color: $text-color;
    }
  }
}

.modal-create-update-location {
  .ant-form.ant-form-vertical {
    padding: 1rem 8px;
  }
}
