.notification-settings-page {
  .row {
    padding: 1rem 8px 8px 8px;
    h3 {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }
  button.ant-btn-primary {
    margin: 1rem 8px;
    width: 7.5rem;
  }
}
