@import '../../styles/variables.scss';

.embed-player-wrapper {
  background: $black;
  color: $white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .live-icon {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1px 8px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    border-radius: 0 0 8px 0;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
